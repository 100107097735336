"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  DEFAULT_ERROR_STRING: () => DEFAULT_ERROR_STRING,
  DEFAULT_UNAVAILABLE_STRING: () => DEFAULT_UNAVAILABLE_STRING,
  FgaRoles: () => FgaRoles,
  checkIfUserHasCaseInsightsAccess: () => checkIfUserHasCaseInsightsAccess,
  checkIfUserHasDashboardAccess: () => checkIfUserHasDashboardAccess,
  checkIfUserIsSuperAdmin: () => checkIfUserIsSuperAdmin,
  checkIsSuperAdminSsr: () => checkIsSuperAdminSsr,
  createBulkCheckBodyRequest: () => createBulkCheckBodyRequest,
  formatSsn: () => formatSsn,
  policyOwner: () => policyOwner,
  toSentenceCase: () => toSentenceCase,
  toTitleCase: () => toTitleCase
});
module.exports = __toCommonJS(src_exports);

// src/auth/auth.ts
var FgaRoles = /* @__PURE__ */ ((FgaRoles2) => {
  FgaRoles2["CASE_STATS_DASHBOARD_ROLE"] = "role:zinnia_read_stats_dashboard";
  FgaRoles2["CASE_INSIGHTS_ROLE"] = "role:zinnia_case_insights";
  return FgaRoles2;
})(FgaRoles || {});
function createBulkCheckBodyRequest(partyId) {
  return {
    tuples: [
      {
        user: `party:${partyId}`,
        relation: "party",
        object: "role:zinnia_super_admin"
      },
      {
        user: `party:${partyId}`,
        relation: "party",
        object: "role:zinnia_read_stats_dashboard" /* CASE_STATS_DASHBOARD_ROLE */
      },
      {
        user: `party:${partyId}`,
        relation: "party",
        object: "role:zinnia_case_insights" /* CASE_INSIGHTS_ROLE */
      }
    ]
  };
}
function checkIfUserIsSuperAdmin(bulkCheckTuples) {
  const superAdminVals = {
    object: "role:zinnia_super_admin",
    relation: "party"
  };
  return bulkCheckTuples.find(
    (tuple) => tuple.object === superAdminVals.object && tuple.relation === superAdminVals.relation && tuple.allowed
  );
}
function checkIfUserHasDashboardAccess(bulkCheckTuples) {
  const superAdminVals = {
    object: "role:zinnia_read_stats_dashboard" /* CASE_STATS_DASHBOARD_ROLE */,
    relation: "party"
  };
  return bulkCheckTuples.find(
    (tuple) => tuple.object === superAdminVals.object && tuple.relation === superAdminVals.relation && tuple.allowed
  );
}
function checkIfUserHasCaseInsightsAccess(bulkCheckTuples) {
  const superAdminVals = {
    object: "role:zinnia_case_insights" /* CASE_INSIGHTS_ROLE */,
    relation: "party"
  };
  return bulkCheckTuples.find(
    (tuple) => tuple.object === superAdminVals.object && tuple.relation === superAdminVals.relation && tuple.allowed
  );
}

// src/data.ts
var policyOwner = (policy) => {
  const ownerParty = policy?.partyRoles?.find(
    (p) => ["Owner", "OWNER"].includes(p.partyRole || "")
  );
  const ownerInfo = policy?.parties?.find(
    (p) => p.partyId === ownerParty?.partyId
  );
  return ownerInfo;
};

// src/strings.ts
var DEFAULT_ERROR_STRING = "-";
var DEFAULT_UNAVAILABLE_STRING = "This data is unavailable at this time. Please try again later.";
var toTitleCase = (value) => {
  if (!value) return "";
  return value.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};
var toSentenceCase = (value) => {
  if (!value) {
    return DEFAULT_ERROR_STRING;
  }
  return value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLowerCase();
};
var formatSsn = (ssn) => {
  if (!ssn) {
    return DEFAULT_ERROR_STRING;
  }
  const cleanedSSN = ssn.replace(/\D/g, "");
  const last4Digits = cleanedSSN.slice(-4);
  return last4Digits;
};

// src/permissions/checkIsSuperAdmin.ts
async function checkIsSuperAdminSsr({
  partyId,
  accessToken
}) {
  try {
    if (!partyId || !accessToken) {
      return false;
    }
    const bulkCheckResponse = await fetch(
      `${process.env.API_BASE_URL}/fga/v1/bulk-check`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(createBulkCheckBodyRequest(partyId))
      }
    );
    const data = await bulkCheckResponse.json();
    const result = checkIfUserIsSuperAdmin(data.tuples);
    return result;
  } catch (error) {
    console.error("checkIsSuperAdmin action", error);
    return false;
  }
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DEFAULT_ERROR_STRING,
  DEFAULT_UNAVAILABLE_STRING,
  FgaRoles,
  checkIfUserHasCaseInsightsAccess,
  checkIfUserHasDashboardAccess,
  checkIfUserIsSuperAdmin,
  checkIsSuperAdminSsr,
  createBulkCheckBodyRequest,
  formatSsn,
  policyOwner,
  toSentenceCase,
  toTitleCase
});
